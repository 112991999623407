@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);

.root {
  display: grid;
  gap: $tokenSpacingLarge4X;
  grid-template-columns: 1fr;
  margin-top: $tokenSpacingDefault;

  @media (--breakpointMediumFrom) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: $tokenSpacingLarge2X;
  }

  & .rootStep {
    display: flex;
    flex-direction: column-reverse;
    gap: $tokenSpacingLarge1X;

    @media (--breakpointMediumFrom) {
      flex-direction: column;
    }

    & .step {
      display: flex;
      flex-direction: column;
      gap: $tokenSpacingDefault;

      & .titleContainer {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        gap: $tokenSpacingDefault;

        & .number {
          align-items: center;
          background-color: $tokenColorsBrandTertiaryDefault;
          border-radius: $tokenSpacingSmall2X;
          color: $tokenColorsBrandDefault;
          display: flex;
          font-size: $tokenFontSizesLarge1X;
          font-weight: $tokenFontWeightsSemiBold;
          height: $tokenSpacingLarge2X;
          justify-content: center;
          width: $tokenSpacingLarge2X;

          @media (--breakpointMediumFrom) {
            font-size: $tokenFontSizesLarge2X;
            height: $tokenSpacingLarge3X;
            width: $tokenSpacingLarge3X;
          }
        }

        & .title {
          color: $tokenColorsGreyscaleDark4X;
          font-size: $tokenFontSizesLarge1X;
          font-weight: $tokenFontWeightsSemiBold;

          @media (--breakpointMediumFrom) {
            font-size: $tokenFontSizesLarge3X;
          }
        }
      }

      & .description {
        font-size: $tokenFontSizesSmall1X;

        @media (--breakpointMediumFrom) {
          font-size: $tokenFontSizesLarge1X;
        }
      }
    }
  }
}
