@import 'vendor/@madpaws/design-system/dist/tokens/tokens.css';

@custom-media --breakpointMediumFrom (min-width: $tokenSizingBreakpointMediumFrom);
@custom-media --breakpointLarge1XFrom (min-width: $tokenSizingBreakpointLarge1XFrom);

.root {
  --margin: $tokenSpacingSmall2X;

  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: $tokenSpacingLarge8X;
  margin-left: var(--margin);
  margin-right: var(--margin);

  @media (--breakpointMediumFrom) {
    --margin: calc(-1 * 10%);

    margin-bottom: calc($tokenSpacingLarge9X + $tokenSpacingSmall2X);
  }

  & h2 {
    margin-bottom: $tokenSpacingSmall2X;
  }
}
